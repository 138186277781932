<template>
  <div id="user-profile">
    <nav
      class="header-navbar navbar-shadow align-items-center navbar-brand-center navbar-fixed"
    >
      <slot name="navbar">
        <profile-header />
      </slot>
    </nav>

    <div class="container" style="width: 90%" v-if="data">
      <div class="mt-4 mb-4">
        <b-breadcrumb class="breadcrumb-chevron">
          <b-breadcrumb-item :to="{ name: 'e-book' }">E-book</b-breadcrumb-item>
          <b-breadcrumb-item active>{{ data?.name }}</b-breadcrumb-item>
        </b-breadcrumb>
      </div>
      <section id="card-images">
        <b-col>
          <b-row>
            <b-col md="6" xl="8">
              <b-card
                no-body
                class="bg-transparent bg-lighten-4"
                style="background-color: transparent; box-shadow: none"
              >
                <b-card-body>
                  <b-card-title style="font-size: 1rem">
                    <!-- <b-link v-for="(tag, index) in blog.tags" :key="index"> -->
                    <b-badge pill class="mr-75 float-left" variant="primary">
                      {{ data?.category?.name }}
                    </b-badge>

                    <!-- </b-link> -->
                  </b-card-title>
                  <b-card-text class="text-dark">
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <p class="text-dark mt-1" style="font-size: 1.25rem">
                        <b>{{ data?.name }}</b>
                      </p>
                    </div>
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <p class="" style="font-size: 1rem">
                        <b>Tentang Buku Ini</b>
                      </p>
                    </div>
                    <div
                      v-html="data.description"
                      class=""
                      style="text-align: left"
                    ></div>
                  </b-card-text>
                  <!-- <hr /> -->
                </b-card-body>
              </b-card>
            </b-col>
            <b-col md="6" xl="4">
              <b-card no-body class="text-left">
                <section>
                  <b-card-body>
                    <div class="text-center">
                      <div class="bg-light-secondary mb-1">
                        <b-img
                          :src="apiFile + data.photo"
                          class="m-2"
                          style="width: 60%"
                        />
                      </div>
                    </div>
                    <b-card-title style="font-size: 1.25rem">
                      Harga
                    </b-card-title>
                    <b-card-text class="text-primary">
                      <span style="font-size: 1.75rem" v-if="data.price > 0">
                        <b>Rp {{ formatRupiah(data.price) }}</b>
                      </span>
                      <span style="font-size: 1.75rem" v-else>
                        <b>Gratis</b>
                      </span>
                    </b-card-text>
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <p class="">
                        <b-badge variant="light-secondary">
                          <feather-icon icon="CalendarIcon" class="mr-25" />
                          <span class="ml-25">
                            Terbit {{ data.publication_year }}
                          </span>
                        </b-badge>
                        <b-badge variant="light-secondary" class="ml-25">
                          <feather-icon icon="BookOpenIcon" class="mr-25" />
                          <span class="ml-25">{{ data.num_page }} Halaman</span>
                        </b-badge>
                      </p>
                    </div>
                    <hr />
                    <div class="d-flex justify-content-between">
                      <!-- <p class="mb-2"> -->
                      <b-button
                        v-if="data.price > 0 && isSiswaMember"
                        variant="primary"
                        block
                        @click="saveEbook"
                        >Tambahkan</b-button
                      >
                      <b-button
                        variant="primary"
                        block
                        :to="{
                          name: 'payment-ebook',
                          params: { id: data.slug },
                        }"
                        v-else-if="data.price > 0 && !isSiswaMember"
                      >
                        Beli
                      </b-button>
                      <b-button
                        variant="primary"
                        block
                        @click="saveEbook"
                        v-else
                      >
                        Tambahkan
                      </b-button>
                      <!-- </p> -->
                    </div>
                  </b-card-body>
                  <!-- </b-link> -->
                </section>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
      </section>
    </div>
    <div class="d-flex justify-content-center" v-else>
      <div class="py-4 mb-4">
        <img
          src="/notfound.svg"
          style="width: 300px"
          alt=""
          class="text-center"
        />
        <h3 class="text-center mt-2 mb-1">E-Book Tidak Ditemukan</h3>
        <div class="text-center">
          <b-button variant="primary" @click.prevent="$router.push('/e-book')"
            >Kembali</b-button
          >
        </div>
      </div>
    </div>
    <footer-home />
  </div>
</template>

<script>
import {
  BRow,
  BImg,
  BCol,
  BCard,
  BCardText,
  BCardTitle,
  BButton,
  BCardBody,
  BCardGroup,
  BBadge,
  BBreadcrumb,
  BBreadcrumbItem,
} from "bootstrap-vue";

import ProfileHeader from "./components/Navbar.vue";
import FooterHome from "./components/Footer.vue";
/* eslint-disable global-require */
export default {
  components: {
    BRow,
    BImg,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BButton,
    BCardBody,
    BCardGroup,
    BBadge,
    BBreadcrumb,
    BBreadcrumbItem,
    FooterHome,
    ProfileHeader,
  },
  data() {
    return {
      loading: false,
      data: null,
    };
  },
  computed: {
    isSiswaMember() {
      if (!this.user) {
        return false;
      }
      return this.user && this.user?.type_member == "member";
    },
  },
  methods: {
    getData() {
      this.loading = true;
      let id = this.$route.params.id;

      this.$store
        .dispatch("e-book/detail", id)
        .then((res) => {
          this.loading = false;
          this.data = res.data;
          this.$store.commit("ebook/SET_DETAIL", res.data);
        })
        .catch((err) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error `,
              icon: "XIcon",
              variant: "danger",
              text: err.response.data.message,
            },
          });
        });
    },
    saveEbook() {
      this.loading = true;
      let payload = {
        ebook_id: this.data.id,
      };

      this.$store
        .dispatch("e-book/saveEbookGratis", [payload])
        .then((res) => {
          this.loading = false;
          this.displaySuccess({
            text: "E-book Tersimpan, Silahkan Lihat di E-book Saya",
          });
          this.$router.push({
            name: "ebook",
          });
        })
        .catch((e) => {
          this.loading = false;
          this.displayError(e);
          return false;
        });
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-profile.scss";
</style>
